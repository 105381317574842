
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { BooleanColumnConfig } from "@/components/controls/catalogueGrid/columns/booleanColumnConfig";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import planCargoRollCreateEditDialog from "./PlanCargoRollCreateEditDialog.vue";
import store from "@/store";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CatalogueGrid,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
    //модель родительского элемента
    parentModel: { required: true },
    //идет расчет плана
    isCalculation: { required: false },
    //открыты ли настройки
    isSettingsOpen: { type: Object, required: true },
  },
  computed: {
    planCargoRollCreateEditDialog(): any {
      return planCargoRollCreateEditDialog;
    },
  },
  methods: {
    setColums() {
      if (ability.can("view", "Modules.CargoPriority")) {
        this.columns.push(
          new BooleanColumnConfig({
            dataField: "highPriority",
            caption: "Обязательно для погрузки",
          })
        );
      }

      if (ability.can("view", "Modules.CargoQueue")) {
        this.columns.push(
          new IntegerColumnConfig({
            dataField: "queuePosition",
            caption: "Порядок погрузки",
            validationRules: [requiredRule],
          })
        );
      }

      if (this.separationWeight) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "weight2",
            caption: "Вес нетто (кг)",
            decimalPoints: 0,
            hideFraction: true,
            validationRules: [requiredRule],
          })
        );

        this.columns.push(
          new DecimalColumnConfig({
            dataField: "totalWeight2",
            caption: "Суммарный вес нетто (кг)",
            decimalPoints: 0,
            hideFraction: true,
            validationRules: [requiredRule],
          })
        );
      } else {
        this.columns.filter((x) => x.dataField == "weight")[0].caption =
          "Вес (кг)";
        this.columns.filter((x) => x.dataField == "totalWeight")[0].caption =
          "Суммарный вес (кг)";
      }

      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "marginMin",
            caption: "Отклонение (-)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new DecimalColumnConfig({
            dataField: "margin",
            caption: "Отклонение (+)",
            decimalPoints: 1,
            validationRules: [requiredRule],
          })
        );
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
          })
        );
      }
    },
  },
  data() {
    return {
      separationWeight: false,
      // подробности работы custom summary см. на сервере в методе SetCustomSummary
      summaryConfig: {
        totalItems: [
          {
            name: "TotalSummary",
            showInColumn: "name",
            displayFormat: "Итог:",
            summaryType: "custom",
          },
          {
            name: "TotalWeightSummary",
            showInColumn: "totalWeight",
            column: "totalWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "TotalWeightSummary2",
            showInColumn: "totalWeight2",
            column: "totalWeight2",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
          {
            name: "CountSummary",
            showInColumn: "count",
            column: "count",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
        ],
        groupItems: [],
      },

      editingConfig: new TableEditingConfig({
        allowDeleteAll: !this.isCalculation,
        allowUpdating: !this.isCalculation,
        allowAdding: !this.isCalculation,
        allowDeleting: !this.isCalculation,
        confirmDelete: true,
        allowDataFill: !this.isCalculation,
        allowExport: false,
        allowImport: false,
        allowCopy: true,
        allowDeleteMultipleRows: true,
        allowChangeScrollSettings: true,
        allowColumnReordering: true,
        allowGrouping: false,
        allowResetSelectedRows: true,
        chooseColumns: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        heightScroll: "calc(100vh - 350px)",
        horizontalScroll: true,
      }),
      columns: [
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
        }),

        new IntegerColumnConfig({
          dataField: "diameter",
          caption: "Диаметр (мм)",
          min: 0,
        }),

        new IntegerColumnConfig({
          dataField: "height",
          caption: "Высота (мм)",
          min: 0,
        }),

        new DecimalColumnConfig({
          dataField: "weight",
          caption: "Вес брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
          validationRules: [requiredRule],
        }),

        new IntegerColumnConfig({
          dataField: "count",
          caption: "Кол-во (шт)",
          min: 0,
          validationRules: [requiredRule],
        }),

        new DecimalColumnConfig({
          dataField: "totalWeight",
          caption: "Суммарный вес брутто (кг)",
          decimalPoints: 0,
          hideFraction: true,
          validationRules: [requiredRule],
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
      repaintPreventKey: false,
      toolbarItems: this.isCalculation ? [] : [],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }

    const settings = (store as any).state.settings.settings;

    if (settings) {
      this.separationWeight = settings.separationWeight;
    }

    this.setColums();
  },
});
